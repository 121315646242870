@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/bootstrap-slider/dist/css/bootstrap-slider.min.css";

/* @import url('https://fonts.googleapis.com/css?family=Open+Sans'); */



html {
    max-width : 1000px;
    margin:0 auto;
}

/* GENERAL */
body {
    font-weight: 300;
    /* color: #363636; */
    color: #000;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    background-color: #fff;
}

.left-green {
    background: #2f792c;
}
.right-image {
    background:  url("assets/img/back1.png") no-repeat;
    width: 100%;
    height: auto;
    max-height: 500px !important;

    /* min-height: 201px; */
   
}

b {
    font-weight: bold;
}
.nobr { white-space:nowrap; }
.slider .bs-tooltip  {
    z-index: -100 !important;
}
.tooltip-inner {
    background-color: #fff;
    color: #000;
    font-size: 10px;
    font-weight: normal;
    border: 1px solid black;
    border-radius: 5px;
    opacity: 1 !important;
    text-align: left;
    margin-bottom: 0px;
    z-index: 1000;
}
.tooltip {
    opacity: 1 !important;
    z-index: 1000;
}

.container {
    background: #fff;
}

.padded-row-extra {
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.padded-row {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.padded-row-container {
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.moveRetAgeUp {
    margin-top: -27px;
}

/* .padded-row-extra {
    /* padding-left: 80px;
    padding-right: 80px; 
} */

.helpIcon {
    margin-left: 3px;
    zoom: 90%;
    margin-top: -2px;
    /* cursor: pointer; */
}
.padded-top {
    padding-top: 20px;
}

.no-pad-col {
    padding-left: 0;
    padding-right: 0;
}

.no-pad-left-col {
    padding-left: 0;
}

.no-pad-right-col {
    padding-right: 0;
}

.row-same-height {
	display: table;
	width: 100%;
	table-layout: fixed;
}
.costIcon {
    zoom: 70%;
}
.col-same-height {
	display: table-cell;
	float: none !important;
}
.costTop {
    height: 170px;
}
.text-value {
    color: #2f792c;
    font-weight: bold;
    /* color: red; */
}
.hsaPaymentImage {
    margin-top: 40px;
}

.test {
    position: relative;
    background-color: #dedede;
    display: inline;
}

.test:before {
    content: '$';
    position: absolute;
    top: 0;
    left: 40px;
    z-index: 1;
}


.hr-thick, .hr-thick-fullwidth {
    border-top: 1px solid #656565;
    /* border-top: 2px solid #d5d5d5; */
    margin: -10px 0 15px 0;
}

.hr-thick-fullwidth {
    margin-left: -20px;
    margin-right: -20px;
}


hr.gray {
    border-top: 2px solid #d5d5d5;
    margin: 40px 0;
}

.font-bold {
    font-weight: 500;
}

p.important {
    color: #2f792c;
    font-size: 22px;
}

p.subtitle {
    font-size: 18px;
}
/* .col {
    flex-grow:inherit !important;
} */
.mb-0 {
    margin-bottom: 0;
}

/* HEADER */
.page-header {
    border-bottom: none;
}
.page-header img {
    display: inline;
    /* margin-right: 10px; */
}

.header-MainTitle {
    font-size: 32px;
    vertical-align: middle;
    color: red;
    margin-left: 10px;
    margin-top: 30px;
}
.header-title {
    font-size: 18px;
    vertical-align: middle;
    color: #000;

}
.progress-row {
    margin: 30px 0 0 0;
    background: #2f792c;
}

.progress-row-xs {
    background: #fff;
}

.progress-title {
    color: #fff;
    font-size: 30px;
    line-height: 100px;
    font-weight: normal;  ;
}

.progress-bar-container {
    margin-top: 45px;
}

.progress-bar-container-xs {
    margin-top: 25px;
}

.progress-bar-container-xs .progress-bar {
    background-color: #2f792c;
}

.progress {
    background-color: #f9f8f6;
    margin-bottom: 2px;
    border-radius: 0;
    height: 15px;
}

.progress-bar {
    background-color: #AAA;
}

.progress-text {
    color: #fff;
    font-size: 11px;
    text-align: right;
}

.progress-text-xs {
    color: #2f792c;
}



.imgcol {
    width: auto;
}
.imgtext {
    text-align: left;
    margin-left: 25px;
    font-size: 14px;
    line-height: 120%;
}
/* LANDING */
.landing-container {
    position: relative;
    /* margin-top: 120px; */
}

.overlay {
    position: absolute;
    top: 30%;
    left: 0;
    background: #2f792c;
    opacity: 0.9;
    height: 30%;
    width: 45%;
}

.overlay-container {
    display: table;
    height: 100%;
    width: 100%;
}

.overlay-text {
    display: table-cell;
    font-size: 28px;
    color: #fff;
    vertical-align: middle;
    padding: 0 40px 0 80px;
    line-height: normal;
}

.padded-content {
    margin-left: -80px;
    padding-right: 10px;
}

.landing-intro1, .landing-tips {
    margin-top: 30px;
}
.landing-tips {
    padding-left: 30px;
}
.infoicon {
    margin-right: 10px;
    margin-top: -10px;
}

.white-home-text {
    color: white;
    font-weight: bold;
    font-size: 34px;
    width: 100%;
    padding: 40px 20px 40px 50px;
    line-height: 120%;
}
.landing-intro2 {
    margin-top: 20px;
    padding-bottom: 50px;
    border-bottom: 2px solid #EDEDED;
}

.landing-header {
    color: #2f792c;
    color: #2f792c;
    font-weight: bold;
    font-size: 20px;
    vertical-align: middle;
    /* padding-top: 20px; */
    text-align: center;
}

.landing-text, .landing-text p {
    /* margin-top: 20px; */
    padding: 0px 10px 10px 18px;
    /* color: #2f792c !important; */
    font-weight: normal;;
}


.green-text, .green-text-bold, .green-tip-hdr, .green-tip-subhdr {
    color: #2f792c !important;
}
.green-tip-hdr {
    font-size: 26px;
    vertical-align: bottom;
}
.green-tip-subhdr {
    font-size: 16px;
}
.green-tip {
    font-weight: bold;
}
.tip-subhdr-text {
    font-size: 14px;
}
.text-bold {
    font-weight: bold;
}
.tip-step1-left, .tip-step1-right {
    margin-left: 10px; 
    margin-top: 10px; 
    padding-left: 55px;
    border-left: 1px solid;
}
.tip-step1-right {
    padding-left: 45px;
}
.green-shaded, .green-shaded-right {
    background-color: #dbe8d8;
    padding: 5px;
}
.green-shaded-right {
    padding-right: 10px !important;
}
.right-summary {
    /* margin-left: -15px; */
    margin-left: -10px;
}
.green-title, .green-title-lg, .green-title-sm, .green-title-cost, .green-title-monthlycost, .green-title-xs {
    color: #2f792c !important;
    font-size: 18px;
    font-weight: bold;
    line-height: 100%;
    margin-bottom: 10px;
}
.green-title-lg {
    font-size: 35px;
}
.green-title-sm {
    font-size: 14px;
}
.green-title-xs {
    font-size: 13px;
    font-weight: normal;
}

.green-title-monthlycost {
    line-height: 60% !important;
}
.get-started-container {
    margin: 20px 0;
    text-align: center;
}
.get-started-arrow {
    padding-left: 2px;
    padding-bottom: 2px;
}



.slider .tooltip {
    cursor: pointer;
}

.slider .tooltip-inner {
    white-space: break-spaces;
    margin-top: -10px;
    text-align: center;
}
#slider-ror .tooltip-main, #slider-ror1 .tooltip-main {
    margin-top: -15px;
}
/* STEPS */
.border-left {
    border-left: 2px solid #AAA !important;
}
.input-row {
    /* margin-top: 10px; */
}
.intro-text {
    font-size: 18px;
    margin: 25px 0;
}
.input-row-large {
    margin-top: 60px;
}

.input-label, .label, .intro-input-label, .intro-input-value, .intro-label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    color: #000;
    line-height: 120%;
}
.intro-input-label, .intro-input-value, .intro-label  {
    font-size: 16px !important;
}

.input-label-blue, .label-blue {
    font-size: 15px;
    color: #2f792c;
}

.input-label-red, .label-red {
    font-size: 14px;
    color: #2f792c;
    color: #2f792c;
    color: #6a6c6c;
}
.vbottom {
    display: inline-block;
    vertical-align: bottom;
    float: none;
}


.coverage-inputs {
    margin-top: 20px;
    margin-bottom: 20px;
}

.coverage-inputs .radio {
    margin-top: 0;
}

.coverage-inputs .radio label {
    padding-left: 0;
    width: 100%;
}

.coverage-image {
    margin-left: 20px;
}

.coverage-box {
    background-color: #2f792c;
    display: table;
    height: 50px;
    width: 100%;
    padding-left: 20px;
}

.coverage-box .radio-container {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
    color: #fff;
}

.input-error {
    color: red;
    margin-top: 2px;
    font-size: 12px;
}


.rpt-intro1 {
    font-weight: bold;
    font-size: 18px;
}
.page-intro {
    color: #7e7b7a;
    margin-bottom: 20px;
    margin-top: 20px;
}

.usage-header {
    background: #2f792c;
    color: #fff;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

.usage-header.active {
    background: #919191;
}

.usage-header.active .btn-usage {
    background: #2f792c;
    color: #fff;
}

.usage-title {
    text-transform: uppercase;
    font-size: 14px;
    /* width: 120px; */
    display:inline-flex;
}

.usage-amount {
    font-size: 26px;
    margin: 10px 0;
}

.usage-amount-xs {
    margin: 0;
    text-align: left;
}

.usage-subtitle {
    font-size: 13px;
    margin-bottom: 20px;
    display: inline;
}

.usage-subtitle-xs {
    margin-bottom: 10px;
    text-align: left;
}
.oopOr {
    font-size: 20px !important;
    font-weight: bold !important;
    text-transform: uppercase;
}
.dropdown-arrow {
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
}

.usage-content {
    padding: 5px;
    /* min-height: 100px; */
    /* border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7; */
}

.usage-body .usage-content:first-child {
    text-align: center;
}

.usage-icon {
    width: 60px;
    text-align: center;
}

.usage-text {
    font-size: 12px;
    vertical-align: middle;
    padding-left: 10px;
    color: #7e7b7a;
}

.divider {
    position: relative;
    text-align: center;
    margin-top: 50px;
}

.divider span {
    background-color: white;
    padding:          0 15px;
    position:         relative;
    z-index:          1;
    text-transform:   uppercase;
    font-size:        12px;
}

.divider:before {
    content:    '';
    background: #d7d7d7;
    display:    block;
    height:     1px;
    position:   absolute;
    top:        50%;
    width:      100%;
    left: 0;
}
.inputSm {
    max-width: 60px; 
}
.inputMd {
    max-width: 90px; 
}
.inputLg {
    max-width: 120px; 
}
chart {
    display: block;
    width: 100%;
}
.no-paddingLeft {
    padding-left: 0px;
}
.no-paddingRight {
    padding-right: 0px;
}

.summaryContainer {
display: inline-block;
    background: #EDEDED;
    padding: 20px
}

.summaryBox {
    background: #ffffff;
    padding: 0 0 20px 20px;
    /* margin-top: 20px; */
    border-left: 2px solid #AAA;
    margin-left: 25px;
}
.highcharts-title {
    width: 100%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.chart-wrapper {
position: relative;
   padding-bottom: 40%;
}

.chart-inner {
position: absolute;
   width: 100%; height: 100%;
}


.legend-container {
    /* background-color: #f9f7f3; */
    /* min-height: 100px; */
    /* padding: 0px 100px 30px;
    padding: 0px 10px 30px; */
}

.legend-box, .legend-box-btn {
    /* min-height: 80px; */
    background: #fff;
    border: 1px solid #d4d4d4;
    /* border-right: 1px solid black; */
    padding: 10px;
    /* cursor: pointer; */
    /* margin-left: -10px; */
}
.legend-box-btn {
    border: none;
    padding: 0px;
}

.legend-box.current {
    border-left: 15px solid #2f792c;
}

.legend-box.alternate {
    border-left: 15px solid #d5d5d5;
}

.legend-title {
    width: 100%;
    vertical-align: middle;
    color: #252525;
    font-weight: bold;
    line-height: 1.1;
    /* font-size: 16px; */
}

.legend-value, .legend-value-gray {
    width: 40%;
    text-align: left;
    vertical-align: middle;
    /* font-size: 24px; */
    color: #2f792c;;
    font-weight: bold;
    font-size: 20px;
}
.legend-value-gray {
    color: #888888;
}

.legend-box.current .legend-value {
    color: #2f792c;
}

.results-header {
    color: #000000;
    color: #2f792c;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 12px;
}

.results-intro {
    color: #7e7b7a;
    margin-bottom: 10px;
}
.normal {
    font-weight: normal !important;
}
.results-box {
    padding: 20px 30px 0 30px;
    margin-bottom: 20px;
}
.blueBack {
    background: #2f792c;
}
.results-box.blue {
    background: #2f792c;
    color: #fff;
}
.blueText, .sliderSubLabel {
    color: #2f792c !important;
}
.sliderSubLabel {
    font-size: 10px;;
}
.results-box.gray {
    background: #BABABA;
    /* color: #000; */
    color: #FFFFFF;
}

.results-box-text {
    font-size: 14px;
}

.results-box-value {
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
}
.results-box-header {
    font-size: 15px;
    width: 100%;
}
.results-box-valueLeft {

    text-align: left;
   
}

.results-box-bold {
    font-weight: 500;
    margin-bottom: 30px;
}

.results-text {
    color: #7e7b7a;
}

.results-text p {
    margin: 30px 0;
}

.action-item {
    display: table-cell;
    vertical-align: top;
    float: none;
}

.action-item.add-border {
    border-right: 2px solid #d7d7d7;
}

.action-header {
    margin-bottom: 20px;
    min-height: 70px;
}

.action-icon {
    width: 61px;
}
.spenderSaverProfile {
    color: #2f792c;
}
.action-icon img {
    margin-left: -10px;
}

.action-title {
    padding-left: 15px;
    color: #2f792c;
    vertical-align: middle;
    line-height: normal;
    font-weight: bold;
    font-size: 18px;
}

.action-body {
    color: #7e7b7a;
    padding-bottom: 10px;
}

.action-body ol {
    padding-left: 1.2em;
}

.action-link {
    cursor: pointer;
}

.center {
    text-align: center;;
}

.callout-icon {
    width: 75px;
    vertical-align: middle;
}

.callout-icon.center {
    text-align: center;
}

.callout-title {
    color: #CE301A;
    font-size: 24px;
    vertical-align: middle;
}

.callout-title-row {
    margin-top: 30px;
}

.callout-title .small, .callout-title.small {
    font-size: 18px;
}

/* .usage-container {
    margin-top: 20px;
} */

.col-bottom {
    position: absolute;
    bottom: 10px;
    height: 60px;
    margin-bottom: 10px;
}
.usage-container, .usage-container-selected {
    /* position: inherit; */
    /* top: -75px; */
    background: white;
    /* height: 650px; */
    width: 80%;
    padding: 30px 0px 100px 0px;
    border-radius: 10px;
    border: 2px solid white;
   
}
.usage-container-selected {
    border: 2px solid #2f792c;
     z-index: 10000;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
} 

.callout-text {
    color: #000;
    margin: 0 0 0 30px;

}
.callout-container, .callout-container-shaded {
    /* position: inherit; */
    /* top: -75px; */
    background: white;
    height: 100%;
    width: 100%;
    -webkit-box-shadow: 0 0 10px #ccc;
    box-shadow: 0 0 10px #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.callout-container-shaded {
    background: linear-gradient(180deg, #eee 50%, #fff 50%);
}
.callout-container-clear {
    /* position: inherit; */
    /* top: -75px; */
    background: white;
    height: 100%;
    width: 100%;
    /* padding: 0 20px; */
    border-radius: 10px;
}


/* CALLOUTS */


/* 
#step1 .callout-container {
    margin-top: 100px;
}

#step2 .callout-container {
    margin-top: 50px;
}

#step4 .callout-container {
    margin-top: 20px;
}

#summary .callout-container {
    min-height: 638px;
} */

.step-footnote {
    margin-top: 100px;
    font-size: 11px;
    color: #9f917c;
}

.summary-label {
    /* color: #6a6c6c; */
    margin-top: 10px;
    /* margin-bottom: 4px; */
    font-weight: bold;
    margin-bottom: 3rem!important;
}

.summary-value {
    /* background: #2f792c; */
    /* background: #2f792c; */
    color: #fff;
    color: #2f792c;
    text-align: left;
    font-weight: bold;
    line-height: 20px;
    font-size: 20px;
}
.step2-input {
    max-width: 100%;
    line-height: 120%;
}
.summary-link .tooltip {
    white-space: nowrap;
}
.nowrap {
    white-space: nowrap;
}

/* FOOTER */
.page-footer {
    /* margin-top: 50px; */
    /* border-top: 6px solid #EDEDED; */
    min-height: 100px;
}

.footer-disclosures {
    margin-top: 20px;
    color: #000;
    font-size: 11px;
}
.summary-footnote {
    color: #000;
    font-size: 12px;
    margin-top: 60px;
    /*     margin-left: -80px; */
}
.footnotes {
    color: #7e7b7a;
    font-size: 12px;
    margin-top: 20px;
}

.house-icon {
    margin-bottom: 4px;
    margin-left: 2px;
}

.font-white {
    color: #fff;
}

.navigation-row {
    /* margin-top: 10px;
    margin-bottom: 50px; */
    /* margin-right: 15px;
    margin-left: 15px; */
}

.nav-btn-container {
    position: relative;
}

.img-prev {
    position: absolute;
    left: 10px;
    top: 12px;
}

.img-next {
    position: absolute;
    right: 0px;
    top: 12px;
}

/* MODALS */
.modal {
    text-align: center;
    padding: 0!important;

}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    min-width: 600px;
}

.modal-header {
    background: #2f792c;
    -webkit-border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topleft: 0;
    -moz-border-radius-topright: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
    color: #fff;
}

.modal-header .close {
    color: #fff;
    opacity: 0.8;
}

.modal-header .close:hover {
    color: #fff;
}

.modal-content {
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.modal-footer {
    border-top: 0;
}
.no-padding, .taxPadding {
    padding-left: 0;
    padding-right: 0;
}
input.D, input.SD, input.P, input.N, input.S {
    height: 34px;
    line-height: 34px;
    border: 2px solid #CCC;
    margin-right: 5px;
}

.summary-panel {
    height: 90%; 
    margin-left: 10px; 
    padding-left: 40px;  
    margin-top:10px;
    border-left: 1px solid;
}
.taxdropdown {
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
}
.statePadding {
    padding-left: 5px !important;
    padding-right: 5px !important;
}
/* .fedPadding, .statePadding {
    padding-left: 10px !important;
    padding-right: 10px !important;
} */
.iconlist {
    width: 50px;
}
/* BUTTONS */
.btn {
    border-radius: 0;
    font-weight: 300;
    font-size: 16px;
    border-radius: 6px;
}
/* .btn:focus, .btn.active:focus, .btn:hover, .btn.active:hover { */
.btn:focus, .btn:hover, .btn-selected:hover, .btn-selected:focus  {
    outline: none;
    /* color: #CCC !important; */
    background-color: white;
    color: #2f792c;
    border: 2px solid #2f792c;
}
.btn-fidelity:hover {
    outline: none;
    /* color: #CCC !important; */
    background-color: #2f792c;
    color: #ffffff;
    border: 2px solid #ffffff;
}

.btn-selected:hover, .btn-selected:focus {
    border: none;
    color: #cccccc;
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
}

.btn-prev:focus, .btn-prev:hover {
    outline: none;
    /* color: #CCC !important; */
    background-color: white;
    color: #b0afaf;
    border: 2px solid #b0afaf;
}

.btn-border {
    border: 1px solid white;
}
.btn-blue {
    margin-bottom: 10px;
}
.btn-fidelity {
    background-color: white;
    color: #2f792c;
    border: 2px solid #2f792c;
    max-width: 250px
}
.btn-next, .btn-pdf, .btn-continue {
    background-color: #2f792c;
    color: white;
    max-width: 250px;
    border: 2px solid #2f792c;
}
.btn-continue {
    min-width: 300px;
}
.btn-prev {
    background-color: #b0afaf;
    color: white;
    max-width: 250px;
    border: 2px solid #b0afaf;
}
.btn-select {
    background-color: #2f792c;
    color: white;
    max-width: 130px;
    font-weight: bold;
    border: 2px solid #2f792c;
}
.btn-selected {
    background-color: white;
    /* color: #2f792c; */
    /* color: rgba(0, 150, 255, 1); */
    color: #cccccc;
    max-width: 140px;
    font-weight: bold;
    /* border: 1px solid #2f792c; */
    border: none;

    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;

    /* outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color; */
}
.btn-getstarted {
    background-color: #2f792c;
    color: white;
    max-width: 180px;
    font-weight: bold;
    border: 2px solid #2f792c;
}
.btn-blue {
    background-color: #2f792c;
    color: #fff;
}
.btn-blue:hover {
    color: #fff;
}
.btn-blue.span {
    font-size: 10px;
}
.btn-white {
    background: #fff;
    color: #2f792c;
    font-weight: 500;
}
.btn-white:hover {
    color: #2f792c;
}
.btn-white.active {
    background: #7e7b7a;
    color: #fff;
    box-shadow: none;
}

.btn-usage {
    text-transform: uppercase;
    font-size: 14px;
}

.btn-usage:hover, .btn-usage:active, .btn-usage:focus {
    color: #2f792c;
}

.btn-right-icon {
    padding-left: 40px;
}

/* INPUTS */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}


.mycontent-left {
    border-right: 1px dashed #333;
  }
.mycontent-right {
    border-left: 1px dashed #333;
  }

.customClass {
    color: red !important;
}
/** SLIDERS **/

/* bootstrap slider plugin */
.slider.slider-horizontal {
    width: 100%;
    margin-top: 16px;
}
.slider-end-label {
    margin-top: 12px;
    color: #2f792c;
    font-weight: bold;
    font-size: 16px;
}

.slider.slider-horizontal .slider-track {
    height: 4px;
    border-radius: 0;
    width: 100% !important;
    left: 0 !important;
}

.slider-track-low, .slider-track-high, .slider-selection {
    border-radius: 0;
}
.slider-track-low {
    color: #2f792c;
}
.slider-selection {
    background: #2f792c;
}

.slider-track-high {
    /* background: #2f792c;
    background: #2f792c; */
    background: #ccc;
    /* background: #D5D5D5; */
}
app-navigation {
    width: 100%;
}

/* Tony */
.slider .tooltip.bs-tooltip-top {
    margin-top: -30px;
    margin-left: 2px !important;
}
.w-70 {
    width: 70%;
}
.slider-2 .slider-selection {
    background: #ccc;
    background: #2f792c;
    box-shadow: none;
}
/* popover-container {
margin-top: 20px;
} */
.popover {
    background-color: #fff;
    color: #000;
    font-size: 10px;
    font-weight: normal;
    border: 1px solid black;
    border-radius: 5px;
    opacity: 1 !important;
    text-align: left;
    margin-bottom: 0px;
    z-index: 1000;
    top: 40px;

}
.popover.bottom .arrow {visibility:hidden;}
.popover .arrow {
    display: none;
}

.popover {

    transform: none !important;
}



.tooltip .tooltip-main .bs-tooltip {
    z-index: 1 !important;
}


.tooltip-main {
    margin-left: -7px !important;
    margin-top: 5px;
    padding: 0px;
}

.slider-handle {

    background: #2f792c;
    border-radius: 14px; 
    width: 14px;
    height: 14px;
    margin-top: -1px;
    margin-left: -5px !important;
    
}
.slider.slider-horizontal .slider-tick, .slider.slider-horizontal  {
    margin-left: 0px !important;
}
.tooltip .arrow {
    opacity: 0;
}
.tooltip  {
    font-weight: bold !important;
    z-index: 1 !important;
    pointer-events: all !important;
    opacity: 1 !important;
}
.shiftUp {
    margin-top: -60px;
}
.form-control {
    font-size: 14px;
}
.bold {
    font-weight: bold !important;
}

.summaryTps {
    margin-top: 0;
}
.slider .tooltip-inner {
    background: transparent;
    /* color: #2f792c; */
    color: #2f792c;
    border: 1px solid #2f792c;
    border-radius: 0;
    font-size: 11px;
    padding: 1px 2px;
    font-weight: bold;
}

.slider .tooltip.top .tooltip-arrow {
    /* border-top-color: #2f792c; */
    border-top-color: transparent;
}
.slider-text {
    margin-top: 18px
}
.slider-labels {
    margin-top: -22px;
    color: #2f792c;
    /* margin-top: 8px; */
    font-size: 14px;
    font-weight: bold;
} 

.hr-nomargin {
    margin: .35rem !important;
}
.hr-hdr {
    margin-left: -20px !important;
    margin-right: -20px !important;
    z-index: -1000px !important;
    border-top: 2px solid #CCC;
}
.hide-hr {
    z-index: 1000;
    min-width: 100px;
}

.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
  }

.navcircle-border-save, .navcircle-border, .navcircle-inner, 
.navcircle, .navcircle-x {
    zoom: 85%;
}

.navcircle-border {
    top: 34px;
    width: 52px !important;
    height: 52px !important;
    opacity: 1;
    display: table-cell;
    vertical-align: middle;
    z-index: -100;
    border-radius: 50%;
    font-size: 24px;
    color: white;
    font-weight: normal;
    background-color: #368727;
    border: 4px solid #fff;
    box-shadow: 0 0 0 3px #368727;
}

.navcircle-inner {
    top: 34px;
    width: 42px;
    height: 42px;
    border: 4px solid white;
    opacity: 1;
    border-radius: 42px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    font-size: 24px;
    color: white;
    font-weight: normal;
    background: #368727;
    z-index: 1000;
}
.navcircle, .navcircle-x {
    top: 34px;
    /* left: 584px; */
    width: 50px;
    height: 50px;
    background: #368727;
    opacity: 1;
    border-radius: 50px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    font-size: 24px;
    color: white;
    font-weight: normal;
    z-index: 1000;
}
.navcircle-x {
    background: #707070;
}

.navtext {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #2f792c;
    z-index: 1000;
    min-width: 100px;
    margin-top: 5px;
    /* margin-left: -10px; */
}
.navalign {
    text-align: -webkit-center;
}

.col-same-height {
    display: grid !important;
}
.slider-spend-container {
    width: 100%;
    padding: 0;
    margin: 0;
    display: table;
}
.icon-piggy-bank, .icon-light-bulb {
    margin-left: -20px;
}
.icon-assumptions {
    margin-left: -10px;
}
.icon-finances {
    margin-left: -20px;
}
.icon-tip-jar {
    margin-left: -30px;
}
.icon-mega-horn {
    height: 40px;
    margin-left: 5px;
}
.no-padding-right{
    padding-right: 0px !important;
}
.no-padding-left{
    padding-left: 0px !important;
}
.icon-spender-saver {
    text-decoration: none;
    display: table-cell;
    width: 5%;
    text-align: center;
    vertical-align: top;
    height: 62px;
}

.slider-spend {
    display: table-cell;
    width: auto;
    margin-top: 15px;
    vertical-align: bottom;
    /* padding: 0 15px; */
    padding: 0;;
}

.slider-tick.in-selection {
    background-color: #2f792c;
    background-image: linear-gradient(to bottom, #2f792c, #2f792c);
    background-repeat: repeat-x;
    /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#52c5ff', endColorstr='#3abcfd',GradientType=0); */
    opacity: 1;
}
.slider-tick {
    background-color: #2f792c;
    background-image: linear-gradient(to bottom, #2f792c, #2f792c   );
    background-repeat: repeat-x;
    /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f792c', endColorstr='#2f792c',GradientType=0); */
    /* box-shadow: inset 0 -1px 0 rgba(0,0,0,0.15); */
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    width: 3px;
    height: 18px;
    filter: none;
    opacity: 0.8;
    border: 0px solid transparent;
    margin-top: -3px;
}
.slider-selection.tick-slider-selection {
    background-color: #2f792c;
    background-image: linear-gradient(to bottom, #2f792c, #2f792c);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f792c', endColorstr='#2f792c',GradientType=0);
}




.icon-text {
    font-size: 12px;
    color: #2f792c;
    text-align: center;
}

/* Webkit */
input[type=range] { -webkit-appearance: none; width: 100%; position: relative; border: 1px solid #dadada; }

input[type=range]::-webkit-slider-runnable-track { width: 100%; border-radius: 0px; height: 24px; background: #2f792c; }
input[type=range]::-webkit-slider-thumb { -webkit-appearance: none; width: 18px; height: 32px; border-radius: 0px; cursor: pointer; margin-top: -4px; background: #dadada; border: 1px solid #2f792c;}

input[type="range"]:focus { -moz-box-shadow: 0 0 8px #dadada !important; -webkit-box-shadow: 0 0 8px #dadada !important; box-shadow: 0 0 8px #dadada !important; outline: none !important; }

/* Mozilla */
input[type=range]::-moz-range-track { border-radius: 0px; height: 7px; background-color: #2f792c; box-shadow: inset 0 2px 2px #dadada; border: none; }
input[type=range]::-moz-range-thumb { width: 18px; height: 18px; border-radius: 10px; cursor: pointer; }

input[type=range]:-moz-focusring { outline: 1px solid #dadada !important; outline-offset: 0; }

/* Internet Explorer */
input[type=range]::-ms-ticks-before,
input[type=range]::-ms-ticks-after,
input[type=range]::-ms-tooltip { display: none; }

input.ie-offset { position: relative; top: -2px; }
input[type=range]::-ms-track { width: 200px; height: 8px; background: transparent; margin-top: 5px; border-color: transparent; border-width: 6px 0; color: transparent; }
input[type=range]::-ms-fill-lower { background: #2f792c; border-radius: 0px; box-shadow: inset 0 2px 2px #dadada; }
input[type=range]::-ms-fill-upper { background: #2f792c; border-radius: 0px; box-shadow: inset 0 2px 2px #dadada; }
input[type=range]::-ms-thumb {  border: none; height: 18px; width: 18px; border-radius: 50%; }
input[type=range]:focus::-ms-fill-lower,
input[type=range]:focus::-ms-fill-upper { background: #2f792c; box-shadow: inset 0 2px 2px #dadada; }

/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
    .hr-hdr {
        width:50px;
        margin-top: 22.5px;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    .hr-hdr {
        width:50px;
        margin-top: 22.5px;
    }


    /* .landing-tips {
        margin-top: 100px;
    } */
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .hr-hdr {
        width: 75px;
        margin-top: 22.5px;
    }
    
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .hr-hdr {
        width:100px;
        margin-top: 22.5px;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .hr-hdr {
        width:120px;
    }
}



/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1199px) {
    .overlay-text {
        font-size: 24px;
        padding: 0 30px 0 40px;
    }
    .padded-content {
        margin-left: -50px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
    .statePadding {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .overlay {
        height: 40%;
    }
    .white-home-text {
        font-size: 28px;
        padding: 20px 20px 20px 50px;
    }
    .overlay-text {
        font-size: 22px;
        padding: 0 20px 0 30px;
    }
    .padded-row, .padded-row-extra {
        padding-left: 20px;
        padding-right: 20px;
    }
    .padded-content {
        margin-left: -20px;
    }
    .landing-header {
        /* padding-top: 10px; */
        color: #2f792c;
        font-weight: bold;
        text-align: center;
    }
    #step1 .callout-container {
        margin-top: 0;
    }
    .action-item {
        display: block;
    }
    .action-item.add-border {
        border-bottom: 2px solid #d7d7d7;
        border-right: none;
    }
    .slider-text {
        font-size: 13px;
    }
    .costMiddle {
        margin-bottom: 40px;
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {

    .moveRetAgeUp {
        margin-top: -100px;
    }
    .green-title-cost {
        height: 25px;
    }
    .cost-icon-col {
        width: 0%;
        padding-left: 0px;
        padding-right: 0px;
    }
    .cost-icon-text {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;;
    }
    .imgtext {
        margin-left: 0;
        font-size: 10px;
    }
    .costIcon{
        display: none;
    }
    .costTop{
        height: 75px;
    }
    .btn {
        font-size: 12px;
        padding: 0.2rem 0.5rem;
    }
    .green-title-cost {
        padding-left: 0px;
        padding-right: 0px;
    }

    .green-tip-hdr {
        font-size: 20px;
    }
    .shiftUp {
        margin-top: 0px;
    }
    .step2-input {
        margin-top: 20px;
    }
    .right-summary {
        margin-top: 50px;
        margin-left: 5px;
    }
    .slider-ror {
        margin-left: 0px;
        margin-right: -30px;
    }
    .slider.slider-horizontal { 

    }
    .summary-label {
        margin-bottom: 0rem!important;
    }
    .summaryTps {
        margin-top: 40px;
    }
    .nav-btn-container {
        margin-top: 5px;
    }
    .landing-tips {
        margin-top: 100px;
    }
    .white-home-text {
        font-size: 20px;
        padding: 10px 20px 10px 30px;
    }
    .overlay {
        top: 20%;
        height: 60%;
    }
    .overlay-text {
        font-size: 18px;
        padding: 0 10px 0 20px;
    }
    .callout-container {
        position: relative;
        top: 0px;
        margin-top: 0px;
        margin-bottom: 50px;
    }
    .step3Tip {
        margin-top: 30px;
    }
    .slider-text {
        font-size: 12px;;
    }
    .costMiddle {
        margin-bottom: 60px;
        margin-top: 40px;
    }
    .slider-end-label {
        margin-top: 30px;
    }
    
    .hr-thick, .hr-thick-fullwidth {
        margin: 10px 0 15px 0;
    }
    .col-bottom {
        height: 100px;
    }
    .green-title-lg {
        font-size: 24px;
    }
}
@media only screen and (max-width : 640px) {

    .slider-2 {
        width: 100%;
    }
    .green-title-lg {
        font-size: 24px;
    }
    .navalign {
        zoom: 0.75;
        -moz-transform: scale(0.75);
    }
    .moveRetAgeUp {
        margin-top: -100px ;
    }
    .costMiddle {
        display: block;
    }
    .green-title-cost {
        margin-bottom: 50px !important;
    }


}



@media only screen and (max-width : 576px) {
    .moveRetAgeUp {
        margin-top: 0px ;
    }
    .callout-container, .callout-container-shaded {

        padding-left: 0.5rem!important;
        padding-right: 0.5rem!important;
    }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

    .green-title-lg {
        font-size: 20px;
    }
    .moveRetAgeUp {
        margin-top: 0px !important;
    }
    .white-home-text {
        font-size: 18px;
        padding: 20px 20px 20px 50px;
    }
    .padded-content {
        margin-left: 0;
    }
    .landing-header {
        padding-top: 0;
        color: #2f792c;
        font-weight: bold;
        text-align: center;
    }
    .page-header {
        padding: 0;
    }
    .header-title {
        font-size: 14px;
    }
    .overlay-text {
        font-size: 16px;
    }
    .slider-spend {
        max-width: 220px;
    }
    .legend-container {
        padding: 15px;
    }
    .results-box {
        padding: 10px;
        text-align: center;
    }
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}

/* Print */
@media print {
    a[href]:after {
        content: '';
    }
    .slider-selection {
        border-top: 18px solid #2f792c;
        background: transparent;
    }

    .slider-track-high {
        border-top: 18px solid #2f792c;
        background: transparent;
    }

    .slider-2 .slider-selection {
        border-top: 18px solid #c9c0b6;
        background: transparent;
    }
    .padded-row, .padded-row-extra {
        padding-left: 0px;
        padding-right: 0px;
    }
    .navigation-row {
        display: none;
    }
}



/****************/
